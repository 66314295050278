import React, { useEffect, useState } from 'react';
import {
  MainWrapper,
  TitleStyles,
  DescriptionStyles,
  TextStyles,
  BusinessRedirectCard,
  FarmRedirectCard,
  ArrowStyles
} from './typeOfAccount.styles';
import { ArrowLeft } from '../../../Assets/images/logos';
import { useTranslation } from 'react-i18next';
import { CompanyRepresentativeForm } from '../Modals/CompanyModal/CompanyDisplayContainer/CompanyRepresentativeForm/CompanyRepresentativeForm';
import { steps } from '../Modals/CompanyModal/CompanyDisplayContainer/CompanyDisplayContainer';
import { useSelector } from 'react-redux';
import { countrySelector } from '../../../Redux/Selectors/questionnaireFlowHandle';
import { CompanyCountryForm } from '../components/companyCountryForm/companyCountryForm';
import { ButtonSection } from '../../ApplicationKYC/LegalEntityQuestionnaire/components';

const allowedFarmerCountries = ['LT'];
const accountTypes = { business: 'business', farm: 'farm' };

export const TypeOfAccountPage = ({
  handleStepChange,
  onSubmit,
  isLoading,
  companyExistsError
}) => {
  const { t } = useTranslation();
  const [selectedCompanyType, setSelectedCompanyType] = useState(null);
  const country = useSelector(countrySelector);
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');
  const [representativeModalOpen, setRepresentativeModalOpen] = useState(false);

  const handleConfirm = () => {
    switch (selectedCompanyType) {
      case accountTypes.business:
        setRepresentativeModalOpen(true);
        break;
      case accountTypes.farm:
        handleStepChange(steps.farm_details_form);
        break;
    }
  };

  useEffect(() => {
    setSelectedCompanyType(null);
  }, [country]);

  return (
    <MainWrapper>
      <ArrowStyles>
        <ArrowLeft onClick={() => handleStepChange(steps.select)} />
      </ArrowStyles>
      <CompanyCountryForm />
      <TitleStyles>
        {isBank ? t('account_type_page_title') : t('account_type_page_title_smego')}
      </TitleStyles>
      <DescriptionStyles>
        {isBank ? t('account_type_page_subtitle') : t('account_type_page_subtitle_smego')}
      </DescriptionStyles>
      <BusinessRedirectCard
        isSelected={accountTypes.business === selectedCompanyType}
        onClick={() => setSelectedCompanyType(accountTypes.business)}
      >
        <TextStyles>{t('account_type_page_business_button')}</TextStyles>
      </BusinessRedirectCard>
      {allowedFarmerCountries.includes(country) && (
        <FarmRedirectCard
          isSelected={accountTypes.farm === selectedCompanyType}
          onClick={() => setSelectedCompanyType(accountTypes.farm)}
        >
          <TextStyles>{t('account_type_page_farm_button')}</TextStyles>
        </FarmRedirectCard>
      )}
      <ButtonSection
        alignEnd={true}
        firstButtonClick={() => handleStepChange(steps.select)}
        secondButtonClick={() => handleConfirm()}
        secondButtonPrimary={true}
        firstButtonText={t('back')}
        secondButtonText={t('next_button')}
        secondButtonDisabled={!selectedCompanyType || isLoading}
      />
      {representativeModalOpen && (
        <CompanyRepresentativeForm
          onSubmit={onSubmit}
          backButtonHandler={() => setRepresentativeModalOpen(!representativeModalOpen)}
          isLoading={isLoading}
          companyExistsError={companyExistsError}
        />
      )}
    </MainWrapper>
  );
};
