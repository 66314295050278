import { CountryConstant } from './';

let country = localStorage.getItem('country');

if (!country) {
  country = 'LT';
}

export const countryReducer = (state = { country }, action) => {
  switch (action.type) {
    case CountryConstant.CHANGE_COUNTRY:
      localStorage.setItem('country', action.payload);
      return {
        ...state,
        country: action.payload
      };
    default: {
      return state;
    }
  }
};
