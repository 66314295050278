import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AuthActions,
  KYCActions,
  AppConfigActions,
  selectGetAuthorizedCompaniesAuthorizations
} from '../../../../../Redux';
import { languageSelector } from '../../../../../Redux/Selectors/questionnaireFlowHandle';
import { steps } from './CompanyDisplayContainer';
import { storageHandler } from '../../../../SSOLoginContainer/storageHandler';
import { Notification, NOTIFICATION_TYPES } from '../../../../../Components';
import { useTranslation } from 'react-i18next';
import { checkJsonProp } from '../../../../../Utilities';
import { useCompanyRefresh } from '../utils/useCompanyRefresh';

export const useCompanyLogic = (clickBackHandler, handleCompanySelect) => {
  const { t } = useTranslation();
  const [farmDetailsData, setFarmDetailsData] = useState({});
  const traceId = useSelector((state) => state.OnboardingFlowReducer.traceId);
  const [selectedCompanyIdentificationId, setSelectedCompanyIdentificationId] = useState('');
  const [company, setCompany] = useState('');
  const [step, setStep] = useState(steps.select);
  const [isLoading, setIsLoading] = useState(false);
  const [companyExistsError, setCompanyExistsError] = useState('');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const availableCompanies = useSelector(selectGetAuthorizedCompaniesAuthorizations);
  const language = useSelector(languageSelector);
  const dispatch = useDispatch();
  const { isSelectingCompany } = useSelector((state) => state?.AuthReducers);
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');
  useCompanyRefresh(isSelectingCompany, setIsInitialLoad);

  useEffect(() => {
    dispatch(AuthActions.getAuthorizedCompanies());
  }, [isInitialLoad]);

  useEffect(() => {
    const fetchAuthorizedCompanies = async () => {
      const token = storageHandler.getSessionStorage('token');
      try {
        if (!availableCompanies && token) {
          await dispatch(AuthActions.getAuthorizedCompanies());
          setIsInitialLoad(false);
        }
      } catch (e) {
        Notification({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('generic_error_message'),
          description: traceId
        });
        clickBackHandler();
      }
    };
    fetchAuthorizedCompanies();
  }, [isInitialLoad]);

  useEffect(() => {
    if (selectedCompany) {
      sessionStorage.setItem('selectedCompanyIdentificationId', selectedCompanyIdentificationId);
    }
  }, [selectedCompany, selectedCompanyIdentificationId]);

  // skip the select page if single valid Ondato. Go to registration. TALE
  useEffect(() => {
    if (singleValidOndatoNoRegisteredCompany(availableCompanies)) {
      setStep(steps.register);
      setSelectedCompanyIdentificationId(availableCompanies[0].identificationId);
    } else if (isRegisteredCompany(availableCompanies)) {
      handleCompanySelect(availableCompanies[0]);
    } else {
      setStep(steps.select);
    }
  }, [availableCompanies]);

  const selectedCompany = useMemo(() => {
    return availableCompanies?.find(
      (company) => company.identificationId === selectedCompanyIdentificationId
    );
  }, [availableCompanies, selectedCompanyIdentificationId]);

  const sortedCompanies = useMemo(() => {
    return availableCompanies?.sort((a, b) => {
      if (!a.companyName || !a.companyCode) {
        return -1;
      }
      if (!b.companyName || !b.companyCode) {
        return 1;
      }
      return 0;
    });
  }, [availableCompanies]);

  const isCompanyEmpty = useMemo(
    () => !(selectedCompany?.companyName && selectedCompany?.companyCode),
    [selectedCompany]
  );

  const singleValidOndatoNoRegisteredCompany = (companies) => {
    if (companies?.length !== 1) return false;
    const company = companies[0];
    return !company.companyName && !company.companyCode;
  };

  const isRegisteredCompany = (companies) => {
    if (companies?.length !== 1) return false;
    const company = companies[0];
    return company.companyName && company.companyCode;
  };

  const handleStepChange = (newStep) => setStep(newStep);

  const handleBackClick = () => {
    clickBackHandler();
  };

  const onCancelHandler = (data) => {
    createCompany(data);
  };

  const onChangeHandler = (companyIdentificationId) => {
    setSelectedCompanyIdentificationId(companyIdentificationId);
  };

  const handleCompanySelectHandler = () => {
    handleCompanySelect(selectedCompany);
  };

  const handleCompanyRepresentativeHandler = () => {
    setStep(steps.type_of_account);
  };

  const handleCompanyRepresentativeRegistrationHandler = async (data) => {
    setIsLoading(true);
    const dataExchangeCheck = await dispatch(
      AuthActions.dataExchangeCheck(data.companyCode, data.country)
    );
    const { agreeToSellDataToOtherCompany } = dataExchangeCheck.payload;
    if (agreeToSellDataToOtherCompany) {
      setCompany({
        companyCode: data.companyCode,
        country: data.country,
        companyName: data.companyName
      });
      setStep(steps.data_exchange);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      createCompany(data);
    }
  };

  const createCompany = async (data) => {
    try {
      setIsLoading(true);
      const referalKey = localStorage.getItem('referalKey');
      const response = await dispatch(
        KYCActions.createApplication(data, selectedCompanyIdentificationId, referalKey)
      );
      await dispatch(
        AppConfigActions.updateApplicationSettings({
          applicationId: response.payload,
          language: language || 'lt-LT'
        })
      );
      handleCompanySelect(selectedCompany);
      setIsLoading(false);
    } catch (e) {
      const value = checkJsonProp(e, 'response.data.errorCode');
      if (value === 'error_codes_company_can_not_register') {
        setIsLoading(false);
        setStep(steps.register);
        setCompanyExistsError(value);
      } else {
        Notification({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('generic_error_message'),
          description: traceId
        });
        clickBackHandler();
      }
    }
  };

  const onContinueHandler = () => {
    createCompany({
      ...company,
      FetchOtherCompanyDataConsent: true,
      TermsAndConditionsConsent: true
    });
  };

  return {
    step,
    isBank,
    isLoading,
    isCompanyEmpty,
    sortedCompanies,
    selectedCompany,
    farmDetailsData,
    onCancelHandler,
    onChangeHandler,
    handleBackClick,
    handleStepChange,
    onContinueHandler,
    setFarmDetailsData,
    companyExistsError,
    handleCompanySelectHandler,
    selectedCompanyIdentificationId,
    handleCompanyRepresentativeHandler,
    handleCompanyRepresentativeRegistrationHandler
  };
};
