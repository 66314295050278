import styled from 'styled-components';

export const MainWrapper = styled.div`
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  max-width: 33.75rem;
  min-width: 33.75rem;

  @media screen and (max-width: 430px) {
    width: 100%;
    min-width: 100%;
    padding: 0 1rem;
  }
`;

export const TitleStyles = styled.div`
  text-align: left;
  font-size: 1.5rem;
  font-weight: 37.5rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
`;
