import React, { useEffect, useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Input, Field, Loader, DatePicker, Dropdown } from '@single-platform/components';
import { useSelector } from 'react-redux';
import countries from '../../../../../../../Auth/components/countriesDropdown/farmersCountryList.json';
import useWindowDimensions from '../../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { FormStyles } from '../../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { RadioChipOption } from '../../../../radioChipOption/radioChipOption';
import { Checkbox } from '../../../../../components';
import { DropdownInput } from '../../../../dropdownInput/dropdownInput';
import { PageSecondaryTextStyles } from '../../../../../farmersQuestionnaire.style';
import { breakpointSizes } from '@single-platform/components/dist/design/designVariables';
import { yesNoChipOptions } from '../../../../../constants';
import { formatAndCleanPepData } from '../../../../../utilities/clearPepInformation';

const defaultValues = {
  isPep: null,
  isPersonPep: false,
  personPepCountry: null,
  personInstitution: null,
  personPosition: null,
  endOfPepStatus: null,
  isFamilyOrAssociatePep: false,
  familyOrAssociateName: null,
  familyOrAssociateSurname: null,
  familyOrAssociateBirthDate: null,
  familyOrAssociateCode: null,
  familyOrAssociateCountry: null,
  familyOrAssociateInstitution: null,
  familyOrAssociatePosition: null,
  familyOrAssociateEndOfPepStatus: null
};

export const PartnerPepPage = ({ onSubmit, onSubmitError, shouldSubmit, initialData }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        isPep: Yup.boolean().required(t('validation_must_be_one_of')).nullable(),
        isPersonPep: Yup.boolean().nullable(),
        isFamilyOrAssociatePep: Yup.boolean().nullable(),
        personPepCountry: Yup.string()
          .nullable()
          .when(['isPep', 'isPersonPep'], {
            is: (isPep, isPersonPep) => isPep && isPersonPep,
            then: Yup.string().required(t('validation_required_field')).nullable()
          }),
        personInstitution: Yup.string()
          .nullable()
          .when(['isPep', 'isPersonPep'], {
            is: (isPep, isPersonPep) => isPep && isPersonPep,
            then: Yup.string().required(t('validation_required_field')).nullable()
          }),
        personPosition: Yup.string()
          .nullable()
          .when(['isPep', 'isPersonPep'], {
            is: (isPep, isPersonPep) => isPep && isPersonPep,
            then: Yup.string().required(t('validation_required_field')).nullable()
          }),
        endOfPepStatus: Yup.date().typeError(t('validation_date_is_not_valid')).nullable(),
        familyOrAssociateName: Yup.string()
          .nullable()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.string().required(t('validation_required_field')).nullable()
          }),
        familyOrAssociateSurname: Yup.string()
          .nullable()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.string().required(t('validation_required_field')).nullable()
          }),
        familyOrAssociateBirthDate: Yup.date()
          .typeError(t('validation_date_is_not_valid'))
          .nullable()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.date()
              .max(new Date(), t('validation_date_entered_must_be_no_further_than_today'))
              .required(t('validation_required_field'))
              .typeError(t('validation_date_is_not_valid'))
              .nullable()
          }),
        familyOrAssociateCode: Yup.string()
          .nullable()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.string().required(t('validation_required_field')).nullable()
          }),
        familyOrAssociateCountry: Yup.string()
          .nullable()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.string().required(t('validation_required_field')).nullable()
          }),
        familyOrAssociateInstitution: Yup.string()
          .nullable()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.string().required(t('validation_required_field')).nullable()
          }),
        familyOrAssociatePosition: Yup.string()
          .nullable()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.string().required(t('validation_required_field')).nullable()
          }),
        familyOrAssociateEndOfPepStatus: Yup.date()
          .typeError(t('validation_date_is_not_valid'))
          .nullable()
      }).test('at-least-one-checkbox', t('validation_must_be_one_of'), function (values) {
        const { isPep, isPersonPep, isFamilyOrAssociatePep } = values;
        if (isPep && !isPersonPep && !isFamilyOrAssociatePep) {
          return this.createError({
            path: 'isPersonPep',
            message: t('validation_must_be_one_of')
          });
        }
        return true;
      }),
    [t]
  );

  const initialValues = useMemo(() => ({ ...defaultValues, ...initialData }), [initialData]);

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit(
        async (data) => {
          const formattedData = formatAndCleanPepData(data);
          await onSubmit?.(formattedData, 'partnerPepPage');
        },
        () => {
          setTouchedOnAll();
          onSubmitError();
        }
      )();
    }
  }, [shouldSubmit, handleSubmit, onSubmit, onSubmitError, setTouchedOnAll]);

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <PageSecondaryTextStyles>{t('farmers_partner_is_pep_header')}</PageSecondaryTextStyles>
      <Field
        Component={RadioChipOption}
        options={yesNoChipOptions}
        value={values.isPep}
        name="isPep"
        validationHelpers={validationHelpers}
        isInModal
        validateOnChange
      />
      {values.isPep && (
        <>
          <Field
            Component={Checkbox}
            name="isPersonPep"
            checked={values.isPersonPep}
            value={values.isPersonPep}
            validationHelpers={validationHelpers}
            label={t('farmers_partner_is_pep_self_checkbox')}
            validateOnChange
          />
          {values.isPersonPep && (
            <>
              <DropdownInput
                validationHelpers={validationHelpers}
                name="personPepCountry"
                value={values.personPepCountry}
                filterCountries={false}
                placeholder={t('farmers_farmer_is_pep_personPepCountry')}
                options={countries}
                validateOnChange
              />
              <Field
                Component={Input}
                name="personInstitution"
                value={values.personInstitution}
                placeholder={t('farmers_farmer_is_pep_personInstitution')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
              <Field
                Component={Input}
                name="personPosition"
                value={values.personPosition}
                placeholder={t('farmers_farmer_is_pep_personPosition')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
              <Field
                Component={DatePicker}
                name="endOfPepStatus"
                value={values.endOfPepStatus?.toString()}
                placeholder={t('farmers_farmer_is_pep_endOfPepStatus')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
            </>
          )}
          <Field
            Component={Checkbox}
            name="isFamilyOrAssociatePep"
            checked={values.isFamilyOrAssociatePep}
            value={values.isFamilyOrAssociatePep}
            validationHelpers={validationHelpers}
            label={t('farmers_partner_is_pep_family_checkbox')}
            validateOnChange
          />
          {values.isFamilyOrAssociatePep && (
            <>
              <Field
                Component={Input}
                name="familyOrAssociateName"
                value={values.familyOrAssociateName}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateName')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
              <Field
                Component={Input}
                name="familyOrAssociateSurname"
                value={values.familyOrAssociateSurname}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateSurname')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
              <Field
                Component={DatePicker}
                name="familyOrAssociateBirthDate"
                value={values.familyOrAssociateBirthDate?.toString()}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateBirthDate')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
              <Field
                Component={Input}
                name="familyOrAssociateCode"
                value={values.familyOrAssociateCode}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateCode')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
              <DropdownInput
                validationHelpers={validationHelpers}
                name="familyOrAssociateCountry"
                value={values.familyOrAssociateCountry}
                filterCountries={false}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateCountry')}
                options={countries}
                validateOnChange
              />
              <Field
                Component={Input}
                name="familyOrAssociateInstitution"
                value={values.familyOrAssociateInstitution}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateInstitution')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
              <Field
                Component={Input}
                name="familyOrAssociatePosition"
                value={values.familyOrAssociatePosition}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociatePosition')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
              <Field
                Component={DatePicker}
                name="familyOrAssociateEndOfPepStatus"
                value={values.familyOrAssociateEndOfPepStatus?.toString()}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateEndOfPepStatus')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
            </>
          )}
        </>
      )}
    </FormStyles>
  );
};
