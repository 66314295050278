import React from 'react';
import {
  CompanyCodeStyles,
  CompanyContainerStyles,
  CompanyNameCodeWrapper,
  CompanyNameStyles,
  RadioSelectWrapper
} from './CompanyContainer.style';
import { RadioButton } from '@single-platform/components';
import { useTranslation } from 'react-i18next';

export const CompanyContainer = ({ isBank, company, onChangeHandler, isSelected }) => {
  const { t } = useTranslation();

  const companyNameText =
    company.companyName ||
    (isBank
      ? t('fill_in_a_new_account_application')
      : t('fill_in_a_new_account_application_smego'));

  return (
    <CompanyContainerStyles
      isSelected={isSelected}
      onClick={() => onChangeHandler(company.identificationId, company.companyName)}
    >
      <RadioSelectWrapper>
        <RadioButton
          style={{ width: '1.2rem' }}
          name={company.identificationId}
          key={company.identificationId}
          id={company.identificationId}
          required={true}
          checked={isSelected}
        />
      </RadioSelectWrapper>
      <CompanyNameCodeWrapper>
        <CompanyNameStyles>{companyNameText}</CompanyNameStyles>
        <CompanyCodeStyles>{company.companyCode}</CompanyCodeStyles>
      </CompanyNameCodeWrapper>
    </CompanyContainerStyles>
  );
};
