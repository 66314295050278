import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Input, Field, Loader, DatePicker } from '@single-platform/components';
import { FarmersQuestionnaireHeader, PageSubtitle } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import countries from '../../../../../../Auth/components/countriesDropdown/farmersCountryList.json';
import { formatLocalDate } from '../../../../utilities/formatLocalDate';
import { ButtonSectionWrapperStyle } from '../../../formStyles';
import { RadioChipOption } from '../../../../components/radioChipOption/radioChipOption';
import { DropdownInput } from '../../../../components/dropdownInput/dropdownInput';
import { identityDocumentTypeOptions } from '../../../../constants';

const defaultValues = {
  type: null,
  dateOfBirth: null,
  personalCode: null,
  documentNumber: null,
  citizenship: null,
  expiryDate: null,
  issuingCountry: null
};

export const RepresentativeDocumentPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        type: Yup.string().nullable().required(t('validation_required_field')),
        dateOfBirth: Yup.date()
          .max(new Date(), t('validation_date_entered_must_be_no_further_than_today'))
          .required(t('validation_required_field'))
          .typeError(t('validation_date_is_not_valid'))
          .nullable(),
        personalCode: Yup.string().nullable().required(t('validation_required_field')),
        documentNumber: Yup.string().nullable().required(t('validation_required_field')),
        citizenship: Yup.string().nullable().required(t('validation_required_field')),
        expiryDate: Yup.date()
          .min(new Date(), t('validation_date_entered_must_be_future_date'))
          .required(t('validation_required_field'))
          .typeError(t('validation_date_is_not_valid'))
          .nullable(),
        issuingCountry: Yup.string().nullable().required(t('validation_required_field'))
      }),
    [t]
  );

  const existingValues = farmerInformation?.farmRepresentativeSection?.representativeDocumentPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        const modifiedData = {
          ...data,
          dateOfBirth: formatLocalDate(data.dateOfBirth),
          expiryDate: formatLocalDate(data.expiryDate)
        };
        await onSubmit?.(modifiedData, 'representativeDocumentPage');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader>
        <PageHeader>{t('farmers_representative_document_header')}</PageHeader>
        <PageSubtitle>{t('farmers_farmer_document_subtitle')}</PageSubtitle>
      </FarmersQuestionnaireHeader>
      <Field
        Component={RadioChipOption}
        options={identityDocumentTypeOptions}
        value={values.type}
        name="type"
        validationHelpers={validationHelpers}
        validateOnChange
      />
      <Field
        Component={Input}
        name="personalCode"
        value={values.personalCode}
        placeholder={t('farmers_farmer_document_personalCode')}
        validationHelpers={validationHelpers}
        validateOnChange
        required
      />
      <Field
        Component={DatePicker}
        name="dateOfBirth"
        required
        showDisabledMonthNavigation
        value={values.dateOfBirth?.toString()}
        placeholder={t('farmers_farmer_document_dateOfBirth')}
        validateOnBlur
        validateOnChange
        showYearDropdown
        yearDropdownItemNumber={99}
        scrollableYearDropdown
        validationHelpers={validationHelpers}
      />
      <Field
        Component={Input}
        type="number"
        name="documentNumber"
        value={values.documentNumber}
        placeholder={t('farmers_farmer_document_documentNumber')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <DropdownInput
        validationHelpers={validationHelpers}
        name="citizenship"
        value={values.citizenship}
        filterCountries={false}
        placeholder={t('farmers_farmer_document_citizenship')}
        options={countries}
        validateOnChange
      />
      <Field
        Component={DatePicker}
        name="expiryDate"
        required
        showDisabledMonthNavigation
        value={values.expiryDate?.toString()}
        placeholder={t('farmers_farmer_document_expiryDate')}
        validateOnBlur
        validateOnChange
        showYearDropdown
        yearDropdownItemNumber={99}
        scrollableYearDropdown
        validationHelpers={validationHelpers}
      />
      <DropdownInput
        validationHelpers={validationHelpers}
        name="issuingCountry"
        value={values.issuingCountry}
        filterCountries={false}
        placeholder={t('farmers_farmer_document_issuingCountry')}
        options={countries}
        validateOnChange
      />
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary={true}
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
