import { CountryConstant } from './constants';

export const CountryActions = {
  setCountry
};

function setCountry(country) {
  return (dispatch) => {
    dispatch(changeCountry(country));
  };

  function changeCountry(data) {
    return { type: CountryConstant.CHANGE_COUNTRY, payload: data };
  }
}
