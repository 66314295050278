import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowLeft } from '../../../Assets/images/logos';
import {
  MainWrapper,
  TitleStyles,
  GapContainer,
  BottomContainer,
  Wrapper,
  StyledButton
} from './FarmDetailsForm.style';
import { Checkbox, Field, Input, LinkButton } from '@single-platform/components';
import {
  ClientConsentContainer,
  ClientConsentText
} from '../Modals/CompanyModal/CompanyDisplayContainer/CompanyRepresentativeForm/CompanyRepresentativeForm.style';
import * as Yup from 'yup';
import { useFormValidation } from '../../ApplicationKYC/LegalEntityQuestionnaire/validation/useFormValidation';
import { useLegalLinks } from '../../../Hooks/useLegalLinks';
import { steps } from '../Modals/CompanyModal/CompanyDisplayContainer/CompanyDisplayContainer';

export const FarmDetailsForm = ({ handleStepChange, setData }) => {
  const { t } = useTranslation();
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  const { openTermsAndConditions, openPrivacyPolicy } = useLegalLinks(isBank);

  const validationSchema = Yup.object({
    country: Yup.string().required(t('validation_required_field')),
    code: Yup.string()
      .test('is-valid-farm-code', t('validation_required_field'), (value) => value.length > 4)
      .required(t('validation_required_field')),
    TermsAndConditionsConsent: Yup.string()
      .test('match-true', 'validation_required_field', (value) => value === 'true')
      .required(t('validation_required_field')),
    MarketingDataUseConsent: Yup.string().optional(),
    MarketingNewsletterConsent: Yup.string().optional()
  });

  const initialValues = {
    country: 'LT',
    code: '',
    TermsAndConditionsConsent: false,
    MarketingDataUseConsent: false,
    MarketingNewsletterConsent: false
  };

  const { handleSubmit, setTouchedOnAll, validationHelpers, getValues } = useFormValidation({
    initialValues,
    validationSchema
  });
  const onSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      (data) => {
        setData(data);
        handleStepChange(steps.who_representing_farm);
      },
      // on invalid case
      () => setTouchedOnAll()
    )();
  };

  return (
    <MainWrapper>
      <Wrapper>
        <ArrowLeft onClick={() => handleStepChange(steps.type_of_account)} />
        <TitleStyles>{t('farm_details_title')}</TitleStyles>
        <GapContainer>
          <Field
            Component={Input}
            name="code"
            placeholder={t('farm_code')}
            validationHelpers={validationHelpers}
            value={getValues('code')}
            validateOnChange
          />
        </GapContainer>
      </Wrapper>
      <BottomContainer>
        <ClientConsentContainer>
          <Field
            Component={Checkbox}
            name="MarketingNewsletterConsent"
            id="MarketingNewsletterConsent"
            validationHelpers={validationHelpers}
            value={getValues('MarketingNewsletterConsent')}
            validateOnChange
          />
          <ClientConsentText>{t('marketing_news_letter_consent')}</ClientConsentText>
        </ClientConsentContainer>

        <ClientConsentContainer>
          <Field
            Component={Checkbox}
            name="MarketingDataUseConsent"
            id="MarketingDataUseConsent"
            validationHelpers={validationHelpers}
            value={getValues('MarketingDataUseConsent')}
            validateOnChange
          />
          <ClientConsentText>{t('marketing_data_use_consent')}</ClientConsentText>
        </ClientConsentContainer>

        <ClientConsentContainer>
          <Field
            Component={Checkbox}
            name="TermsAndConditionsConsent"
            id="TermsAndConditionsConsent"
            validationHelpers={validationHelpers}
            value={getValues('TermsAndConditionsConsent')}
            validateOnChange
          />
          <ClientConsentText>
            <Trans i18nKey="client_consent_text">
              Text
              <LinkButton onClick={openTermsAndConditions}>Link</LinkButton>
              Text
              <LinkButton onClick={openPrivacyPolicy}>Link</LinkButton>
            </Trans>
          </ClientConsentText>
        </ClientConsentContainer>
        <Field Component={StyledButton} type="submit" onClick={onSubmit}>
          {t('button_save_and_continue')}
        </Field>
      </BottomContainer>
    </MainWrapper>
  );
};
