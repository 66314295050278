import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Input, Field, Loader, DatePicker } from '@single-platform/components';
import { useSelector } from 'react-redux';
import countries from '../../../../../../../Auth/components/countriesDropdown/farmersCountryList.json';
import useWindowDimensions from '../../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { useFarmerFormValidation } from '../../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import { formatLocalDate } from '../../../../../utilities/formatLocalDate';
import { breakpointSizes } from '@single-platform/components/dist/design/designVariables';
import { RadioChipOption } from '../../../../radioChipOption/radioChipOption';
import { FormStyles } from '../../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { DropdownInput } from '../../../../dropdownInput/dropdownInput';
import { PageSecondaryTextStyles } from '../../../../../farmersQuestionnaire.style';
import { identityDocumentTypeOptions } from '../../../../../constants';

const defaultValues = {
  type: null,
  dateOfBirth: null,
  personalCode: null,
  documentNumber: null,
  citizenship: null,
  expiryDate: null,
  issuingCountry: null
};

export const PartnerDocumentPage = ({ onSubmit, onSubmitError, shouldSubmit, initialData }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const initialValues = useMemo(() => ({ ...defaultValues, ...initialData }), [initialData]);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        type: Yup.string().required(t('validation_must_be_one_of')).nullable(),
        dateOfBirth: Yup.date()
          .required(t('validation_required_field'))
          .max(new Date(), t('validation_date_entered_must_be_no_further_than_today'))
          .typeError(t('validation_date_is_not_valid'))
          .nullable(),
        personalCode: Yup.string().required(t('validation_required_field')).nullable(),
        documentNumber: Yup.string().required(t('validation_required_field')).nullable(),
        citizenship: Yup.string().required(t('validation_required_field')).nullable(),
        expiryDate: Yup.date()
          .required(t('validation_required_field'))
          .min(new Date(), t('validation_date_entered_must_be_future_date'))
          .typeError(t('validation_date_is_not_valid'))
          .nullable(),
        issuingCountry: Yup.string().required(t('validation_required_field')).nullable()
      }),
    [t]
  );

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit(
        async (data) => {
          const modifiedData = {
            ...data,
            dateOfBirth: formatLocalDate(data.dateOfBirth),
            expiryDate: formatLocalDate(data.expiryDate)
          };
          await onSubmit?.(modifiedData, 'partnerDocumentPage');
        },
        () => {
          setTouchedOnAll();
          onSubmitError();
        }
      )();
    }
  }, [shouldSubmit, handleSubmit, onSubmit, onSubmitError, setTouchedOnAll]);

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <PageSecondaryTextStyles>{t('farmers_document_type')}</PageSecondaryTextStyles>
      <Field
        Component={RadioChipOption}
        options={identityDocumentTypeOptions}
        value={values.type}
        name="type"
        isInModal
        validationHelpers={validationHelpers}
        validateOnChange
      />
      <Field
        Component={Input}
        name="personalCode"
        value={values.personalCode}
        placeholder={t('farmers_farmer_document_personalCode')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <Field
        Component={DatePicker}
        name="dateOfBirth"
        required
        showDisabledMonthNavigation
        value={values.dateOfBirth?.toString()}
        placeholder={t('farmers_farmer_document_dateOfBirth')}
        validateOnBlur
        validateOnChange
        showYearDropdown
        yearDropdownItemNumber={99}
        scrollableYearDropdown
        validationHelpers={validationHelpers}
      />
      <Field
        Component={Input}
        type="number"
        name="documentNumber"
        value={values.documentNumber}
        placeholder={t('farmers_farmer_document_documentNumber')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <DropdownInput
        validationHelpers={validationHelpers}
        name="citizenship"
        value={values.citizenship}
        filterCountries={false}
        placeholder={t('farmers_farmer_document_citizenship')}
        options={countries}
        validateOnChange
      />
      <Field
        Component={DatePicker}
        name="expiryDate"
        required
        showDisabledMonthNavigation
        value={values.expiryDate?.toString()}
        placeholder={t('farmers_farmer_document_expiryDate')}
        validateOnBlur
        validateOnChange
        showYearDropdown
        yearDropdownItemNumber={99}
        scrollableYearDropdown
        validationHelpers={validationHelpers}
      />
      <DropdownInput
        validationHelpers={validationHelpers}
        name="issuingCountry"
        value={values.issuingCountry}
        filterCountries={false}
        placeholder={t('farmers_farmer_document_issuingCountry')}
        options={countries}
        validateOnChange
      />
    </FormStyles>
  );
};
