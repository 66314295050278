import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthActions, selectIsStartLoginloading } from '@redux';
import { LoginBubble } from './Components/LoginBubble/LoginBubble';
import { LoginForm } from './Components/LoginForm';
import { logError, Logout } from '../../Utilities';
import { useTranslation } from 'react-i18next';
import CountryForm from '../Auth/components/countryForm/countryForm';
import Icons from '@single-platform/components/dist/assets/icons';
import {
  AuthenticationContainerStyle,
  AuthenticationHeaderContainer,
  LinkButtonModified,
  LoginContainerStyles,
  LoginHeaderStyles
} from './styles/login.styles';
import { FormSubheading } from '../Auth/components/countryForm/countryForm.styles';
import { routerPath } from '../../Config';
import { CountryActions } from '../../Redux';

const DEFAULT_SUBMIT_STATUS = {
  type: null,
  isSubmitting: false
};

export const LoginContainer = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsStartLoginloading);
  const { t } = useTranslation();
  const authState = useSelector((state) => state.AuthReducers);
  const [isBackButtonVisible, setIsBackButtonVisible] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({
    type: null,
    isSubmitting: false
  });
  const [step, setStep] = useState('countrySelect');
  const [personalData, setPersonalData] = useState('');

  const submitLogin = useCallback(
    async (values) => {
      const titledAuthType = values.authType.charAt(0).toUpperCase() + values.authType.slice(1);
      try {
        setSubmitStatus({
          isSubmitting: true,
          type: titledAuthType
        });
        await dispatch(AuthActions.startLogin({ ...values }));
      } catch (e) {
        logError(e);
        resetAll();
      }
    },
    [dispatch]
  );

  const logonSelection = (personalData) => {
    dispatch(CountryActions.setCountry(personalData.country));
    setPersonalData(personalData);
    setStep('personalInfo');
  };
  const onBack = () => {
    setStep('countrySelect');
  };

  const resetAll = useCallback(() => {
    setSubmitStatus(DEFAULT_SUBMIT_STATUS);
  }, []);

  const urlSearchParamsToObject = (searchParams) => {
    const params = new URLSearchParams(searchParams);
    const obj = {};

    for (const [key, value] of params.entries()) {
      obj[key] = value;
    }

    return obj;
  };

  useEffect(() => {
    if (authState.isWaitingForConfirmation !== undefined)
      setIsBackButtonVisible(authState.isWaitingForConfirmation);
  }, [authState.isWaitingForConfirmation, submitStatus.isSubmitting]);

  useEffect(() => {
    Logout();
    const url = new URL(window.location.href);
    if (routerPath.redirectEIdEasy.includes(url.pathname)) {
      const searchParams = urlSearchParamsToObject(url.search);
      if (!searchParams.language)
        localStorage.setItem('redirectParams', JSON.stringify(searchParams));
    }
    const eideasy = localStorage.getItem('eidEasy');
    setStep(JSON.parse(eideasy)?.step || 'countrySelect');
  }, []);

  return (
    <LoginContainerStyles>
      <AuthenticationContainerStyle>
        <AuthenticationHeaderContainer>
          {step === 'personalInfo' && !isBackButtonVisible && (
            <FormSubheading>
              <LinkButtonModified onClick={onBack}>
                <Icons.ArrowLeft />
                {t('back')}
              </LinkButtonModified>
            </FormSubheading>
          )}
        </AuthenticationHeaderContainer>

        {step === 'countrySelect' ? (
          <CountryForm
            allowCountrySelection
            formSubheading={t('enter_email_to_continue_login_placeholder')}
            inputPlaceholder={t('question_address_email')}
            buttonLabel={t('flow_selection_button')}
            onSubmit={logonSelection}
          />
        ) : (
          <>
            <LoginHeaderStyles>
              {submitStatus.type
                ? `${t('login_header_login_with_auth')} ${t(submitStatus.type)}`
                : t('login_header_login_with_text')}
            </LoginHeaderStyles>
            <LoginForm
              onSubmit={submitLogin}
              personalData={personalData}
              setSubmitStatus={setSubmitStatus}
              isSubmitting={submitStatus.isSubmitting || isLoading}
              step={step}
              setStep={setStep}
            />
          </>
        )}
      </AuthenticationContainerStyle>
      <LoginBubble />
    </LoginContainerStyles>
  );
};
