import React, { useMemo, useEffect } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Input, Field, Loader, DatePicker } from '@single-platform/components';
import {
  FarmersQuestionnaireHeader,
  PopupContainer,
  PageSecondaryTextStyles
} from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import countries from '../../../../../../Auth/components/countriesDropdown/farmersCountryList.json';
import { ButtonSectionWrapperStyle } from '../../../formStyles';
import { RadioChipOption } from '../../../../components/radioChipOption/radioChipOption';
import { LinkPopup } from '../../../../../LegalEntityQuestionnaire/components/linkPopup/linkPopup';
import { Checkbox } from '../../../../components';
import { DropdownInput } from '../../../../components/dropdownInput/dropdownInput';
import { formatAndCleanPepData } from '../../../../utilities/clearPepInformation';
import { yesNoChipOptions } from '../../../../constants';
import { history } from '../../../../../../../Config';
import { today } from '../../../../../../../Constants/date';

const defaultValues = {
  isPep: null,
  isPersonPep: false,
  personPepCountry: null,
  personInstitution: null,
  personPosition: null,
  endOfPepStatus: null,
  isFamilyOrAssociatePep: false,
  familyOrAssociateName: null,
  familyOrAssociateSurname: null,
  familyOrAssociateBirthDate: null,
  familyOrAssociateCode: null,
  familyOrAssociateCountry: null,
  familyOrAssociateInstitution: null,
  familyOrAssociatePosition: null,
  familyOrAssociateEndOfPepStatus: null
};

const linkPopupGroup = {
  text: 'link_popup_who_is_pep_description_title',
  popupKey: 'link_popup_aml'
};

export const RepresentativesPepPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        isPep: Yup.boolean().required(t('validation_must_be_one_of')).nullable(),
        isPersonPep: Yup.boolean(),
        isFamilyOrAssociatePep: Yup.boolean(),
        personPepCountry: Yup.string()
          .when(['isPep', 'isPersonPep'], {
            is: (isPep, isPersonPep) => isPep && isPersonPep,
            then: Yup.string().required(t('validation_required_field'))
          })
          .nullable(),
        personInstitution: Yup.string()
          .when(['isPep', 'isPersonPep'], {
            is: (isPep, isPersonPep) => isPep && isPersonPep,
            then: Yup.string().required(t('validation_required_field'))
          })
          .nullable(),
        personPosition: Yup.string()
          .when(['isPep', 'isPersonPep'], {
            is: (isPep, isPersonPep) => isPep && isPersonPep,
            then: Yup.string().required(t('validation_required_field'))
          })
          .nullable(),
        endOfPepStatus: Yup.date().typeError(t('validation_date_is_not_valid')).nullable(),
        familyOrAssociateName: Yup.string()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.string().required(t('validation_required_field'))
          })
          .nullable(),
        familyOrAssociateSurname: Yup.string()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.string().required(t('validation_required_field'))
          })
          .nullable(),
        familyOrAssociateBirthDate: Yup.date()
          .typeError(t('validation_date_is_not_valid'))
          .nullable()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.date()
              .max(today, t('validation_date_entered_must_be_no_further_than_today'))
              .required(t('validation_required_field'))
              .typeError(t('validation_date_is_not_valid'))
              .nullable()
          }),
        familyOrAssociateCode: Yup.string()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.string().required(t('validation_required_field'))
          })
          .nullable(),
        familyOrAssociateCountry: Yup.string()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.string().required(t('validation_required_field'))
          })
          .nullable(),
        familyOrAssociateInstitution: Yup.string()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.string().required(t('validation_required_field'))
          })
          .nullable(),
        familyOrAssociatePosition: Yup.string()
          .when(['isPep', 'isFamilyOrAssociatePep'], {
            is: (isPep, isFamilyOrAssociatePep) => isPep && isFamilyOrAssociatePep,
            then: Yup.string().required(t('validation_required_field'))
          })
          .nullable(),
        familyOrAssociateEndOfPepStatus: Yup.date()
          .typeError(t('validation_date_is_not_valid'))
          .nullable()
      }).test('at-least-one-checkbox', t('validation_must_be_one_of'), function (values) {
        const { isPep, isPersonPep, isFamilyOrAssociatePep } = values;
        if (isPep === true && !isPersonPep && !isFamilyOrAssociatePep) {
          return this.createError({
            path: 'isPersonPep',
            message: t('validation_must_be_one_of')
          });
        }
        return true;
      }),
    [t]
  );

  const existingValues = farmerInformation?.farmRepresentativeSection?.representativesPepPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const {
    validationHelpers,
    getValues,
    setTouchedOnAll,
    handleSubmit,
    setValues
  } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  useEffect(() => {
    const values = getValues();
    if (values.isPep === false) {
      setValues({ ...defaultValues, isPep: false });
    }
  }, [getValues, setValues]);

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        const formattedData = formatAndCleanPepData(data);
        await onSubmit(formattedData, 'representativesPepPage');
        history.push('/FarmersQuestionnaire?success=farmRepresentativeSection');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader>
        <PageHeader>{t('farmers_representative_pep_header')}</PageHeader>
      </FarmersQuestionnaireHeader>
      <PopupContainer>
        <LinkPopup group={linkPopupGroup} farmers />
      </PopupContainer>
      <Field
        Component={RadioChipOption}
        options={yesNoChipOptions}
        value={values.isPep}
        name="isPep"
        validationHelpers={validationHelpers}
        validateOnChange
      />
      {values.isPep && (
        <>
          <PageSecondaryTextStyles>
            {t('farmers_farmer_is_pep_description')}
          </PageSecondaryTextStyles>
          <Field
            Component={Checkbox}
            name="isPersonPep"
            checked={values.isPersonPep}
            value={values.isPersonPep}
            validationHelpers={validationHelpers}
            label={t('choices_pep_person_myself')}
            validateOnChange
          />

          {values.isPersonPep && (
            <>
              <DropdownInput
                validationHelpers={validationHelpers}
                name="personPepCountry"
                value={values.personPepCountry}
                filterCountries={false}
                placeholder={t('farmers_farmer_is_pep_personPepCountry')}
                options={countries}
                validateOnChange
              />
              <Field
                Component={Input}
                name="personInstitution"
                value={values.personInstitution}
                placeholder={t('farmers_farmer_is_pep_personInstitution')}
                validationHelpers={validationHelpers}
                validateOnChange
                required
              />
              <Field
                Component={Input}
                name="personPosition"
                value={values.personPosition}
                placeholder={t('farmers_farmer_is_pep_personPosition')}
                validationHelpers={validationHelpers}
                validateOnChange
                required
              />
              <Field
                Component={DatePicker}
                name="endOfPepStatus"
                value={values.endOfPepStatus?.toString()}
                placeholder={t('farmers_farmer_is_pep_endOfPepStatus')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
            </>
          )}
          <Field
            Component={Checkbox}
            name="isFamilyOrAssociatePep"
            checked={values.isFamilyOrAssociatePep}
            value={values.isFamilyOrAssociatePep}
            validationHelpers={validationHelpers}
            label={t('choices_pep_person_family_member')}
            validateOnChange
          />
          {values.isFamilyOrAssociatePep && (
            <>
              <Field
                Component={Input}
                name="familyOrAssociateName"
                value={values.familyOrAssociateName}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateName')}
                validationHelpers={validationHelpers}
                validateOnChange
                required
              />
              <Field
                Component={Input}
                name="familyOrAssociateSurname"
                value={values.familyOrAssociateSurname}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateSurname')}
                validationHelpers={validationHelpers}
                validateOnChange
                required
              />
              <Field
                Component={DatePicker}
                name="familyOrAssociateBirthDate"
                value={values.familyOrAssociateBirthDate?.toString()}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateBirthDate')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
              <Field
                Component={Input}
                name="familyOrAssociateCode"
                value={values.familyOrAssociateCode}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateCode')}
                validationHelpers={validationHelpers}
                validateOnChange
                required
              />
              <DropdownInput
                validationHelpers={validationHelpers}
                name="familyOrAssociateCountry"
                value={values.familyOrAssociateCountry}
                filterCountries={false}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateCountry')}
                options={countries}
                validateOnChange
              />
              <Field
                Component={Input}
                name="familyOrAssociateInstitution"
                value={values.familyOrAssociateInstitution}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateInstitution')}
                validationHelpers={validationHelpers}
                validateOnChange
                required
              />
              <Field
                Component={Input}
                name="familyOrAssociatePosition"
                value={values.familyOrAssociatePosition}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociatePosition')}
                validationHelpers={validationHelpers}
                validateOnChange
                required
              />
              <Field
                Component={DatePicker}
                name="familyOrAssociateEndOfPepStatus"
                value={values.familyOrAssociateEndOfPepStatus?.toString()}
                placeholder={t('farmers_farmer_is_pep_familyOrAssociateEndOfPepStatus')}
                validationHelpers={validationHelpers}
                validateOnChange
              />
            </>
          )}
        </>
      )}
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary={true}
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
