import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { MainWrapper, TitleStyles } from './companyCountryForm.styles';
import { useTranslation } from 'react-i18next';
import { Fieldset } from '@single-platform/components';
import { useFormValidation } from '../../../ApplicationKYC/LegalEntityQuestionnaire/validation/useFormValidation';
import { CountriesDropdown } from '../countriesDropdown/countriesDropdown';
import countries from '../../components/countriesDropdown/countriesOfRegistrationList.json';
import { useDispatch, useSelector } from 'react-redux';
import { countrySelector } from '../../../../Redux/Selectors/questionnaireFlowHandle';
import { CountryActions } from '../../../../Redux';

const initialValues = {
  country: ''
};

export const CompanyCountryForm = () => {
  const { t } = useTranslation();
  const country = useSelector(countrySelector);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    country: Yup.string().required(t('validation_required_field'))
  });
  const { validationHelpers, getValues } = useFormValidation({
    initialValues,
    validationSchema
  });

  useEffect(() => {
    const selectedCountry = getValues('country');
    if (selectedCountry && selectedCountry !== country) {
      dispatch(CountryActions.setCountry(selectedCountry));
    }
  });

  return (
    <MainWrapper>
      <TitleStyles className="title">{t('question_country_selection')}</TitleStyles>
      <Fieldset>
        <CountriesDropdown
          validationHelpers={validationHelpers}
          value={country}
          placeholder={t('question_country')}
          countries={countries}
          filterCountries
        />
      </Fieldset>
    </MainWrapper>
  );
};
