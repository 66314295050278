import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyContainer } from './CompanyContainer/CompanyContainer';
import {
  CompaniesContainerStyles,
  CompanyDisplayContainerStyles,
  CompanyDisplayContainerWrapper,
  TitleStyles,
  SubtitleStyles
} from './CompanyDisplayContainer.style';

import { DataExchangeModal } from '../../DataExchange/DataExchange';
import {
  ButtonSection,
  LoaderWithText
} from '../../../../ApplicationKYC/LegalEntityQuestionnaire/components';
import CreateEmptyCompanyButton from './CreateEmptyCompanyButton/CreateEmptyCompanyButton';
import { TypeOfAccountPage } from '../../../TypeOfAccount/typeOfAccount';
import { FarmDetailsForm } from '../../../Farm/FarmDetailsForm';
import { WhoRepresentingFarm } from '../../../Farm/WhoRepresentingFarm';
import { useCompanyLogic } from './useCompanyLogic';

export const steps = {
  select: 'Select',
  data_exchange: 'DataExchange',
  type_of_account: 'TypeOfAccount',
  register: 'Register',
  farm_details_form: 'FarmDetailsForm',
  who_representing_farm: 'WhoRepresentingFarm'
};

export const CompanyDisplayContainer = memo(({ clickBackHandler, handleCompanySelect }) => {
  const { t } = useTranslation();

  const {
    step,
    isBank,
    isLoading,
    isCompanyEmpty,
    sortedCompanies,
    selectedCompany,
    farmDetailsData,
    onCancelHandler,
    onChangeHandler,
    handleBackClick,
    handleStepChange,
    onContinueHandler,
    setFarmDetailsData,
    companyExistsError,
    handleCompanySelectHandler,
    selectedCompanyIdentificationId,
    handleCompanyRepresentativeHandler,
    handleCompanyRepresentativeRegistrationHandler
  } = useCompanyLogic(clickBackHandler, handleCompanySelect);
  useEffect(() => {
    window.addEventListener('load', () => {
      document.body.style.overflow = 'hidden';
    });
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const renderStepComponent = () => {
    switch (step) {
      case steps.select:
        return (
          <CompanyDisplayContainerStyles>
            {(process.env.REACT_APP_ENV === 'TEST' || process.env.REACT_APP_ENV === 'STAGE') && (
              <CreateEmptyCompanyButton />
            )}
            <CompaniesContainerStyles>
              {sortedCompanies?.map((company) => (
                <CompanyContainer
                  isBank={isBank}
                  key={company.identificationId}
                  company={company}
                  onChangeHandler={onChangeHandler}
                  isSelected={company.identificationId === selectedCompanyIdentificationId}
                />
              ))}
            </CompaniesContainerStyles>
            <ButtonSection
              alignEnd={true}
              firstButtonClick={handleBackClick}
              secondButtonClick={
                !selectedCompanyIdentificationId || !isCompanyEmpty
                  ? () => handleCompanySelectHandler()
                  : handleCompanyRepresentativeHandler
              }
              secondButtonPrimary={true}
              firstButtonText={
                step === steps.select ? t('repeatable_group.cancel_button') : t('back')
              }
              secondButtonText={!isCompanyEmpty ? t('login_button') : t('next_button')}
              secondButtonDisabled={
                (!selectedCompanyIdentificationId && step === steps.select) || isLoading
              }
            />
          </CompanyDisplayContainerStyles>
        );
      case steps.data_exchange:
        return (
          <DataExchangeModal
            isOpen={true}
            onContinue={onContinueHandler}
            onCancel={onCancelHandler}
          />
        );
      case steps.type_of_account:
      case steps.register:
        return (
          <TypeOfAccountPage
            handleStepChange={handleStepChange}
            onSubmit={handleCompanyRepresentativeRegistrationHandler}
            isLoading={isLoading}
            companyExistsError={companyExistsError}
          />
        );
      case steps.farm_details_form:
        return <FarmDetailsForm handleStepChange={handleStepChange} setData={setFarmDetailsData} />;
      case steps.who_representing_farm:
        return (
          <WhoRepresentingFarm
            handleStepChange={handleStepChange}
            farmDetailsData={farmDetailsData}
            selectedCompanyIdentificationId={selectedCompanyIdentificationId}
            selectedCompany={selectedCompany}
            handleCompanySelectHandler={handleCompanySelectHandler}
          />
        );
      default:
        return null;
    }
  };

  const companyDescription = isBank
    ? t('select_your_company_description')
    : t('select_your_company_description_smego');

  const getLabel = (step) => {
    if (step === steps.select && !isLoading) {
      return (
        <>
          <TitleStyles>
            {isBank ? t('select_your_company') : t('select_your_company_smego')}
          </TitleStyles>
          <SubtitleStyles>{companyDescription}</SubtitleStyles>
        </>
      );
    }
    return '';
  };

  if (isLoading) {
    return <LoaderWithText small={true} text={t('loader_preparing_questionnaire_text')} />;
  }

  return (
    <CompanyDisplayContainerWrapper>
      <>
        {getLabel(step)}
        {renderStepComponent()}
      </>
    </CompanyDisplayContainerWrapper>
  );
});
